<template>
  <!-- 系统异常页面 -->
  <div class="padding24 color_white" id="system_abnormal">
    <div v-show="$route.meta.isShow">
      <!-- 顶部 -->
      <div class="top">
        <div class="top_left"></div>
        <div class="top_rigiht">
          <span>创建日期 :<a-range-picker @change="onChangeDate" /> </span>
          <span
            >异常来源
            <a-select
              v-model="abnormal"
              style="width: 120px"
              :getPopupContainer="
                (triggerNode) => triggerNode.parentNode || document.body
              "
              @change="changeAbnormal"
            >
              <a-select-option v-for="item in abnormalArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </span>
          <a-input
            v-model="searchVal"
            placeholder="请输入异常id"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button
            icon="search"
            type="primary"
            @click="searchClick"
            :disabled="isDisableSearch"
            >搜索</a-button
          >
        </div>
      </div>
      <div class="btn">
        <a-button type="danger" @click="startClick">更新日志</a-button>
        <a-button
          type="danger"
          @click="emptyClick"
          :disabled="isDisableEmptyLog"
          >清空日志</a-button
        >
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
      >
        <span slot="code" slot-scope="code, record">
          <span v-show="record.code == 1">app-consumer</span>
          <span v-show="record.code == 2">app-provider</span>
          <span v-show="record.code == 3">admin-consumer</span>
          <span v-show="record.code == 4">admin-provider</span>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a @click="seeClick(record)">查看</a>
        </span>
      </a-table>
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading && tableData.length > 0"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import {
  LogExceptionListApi,
  SaveBatchErrorLogApi,
  ExceptionDellAllApi,
} from "@/request/api/systemManage";
import MyPagination from "@/components/pagination/MyPagination";
import { message, Modal } from "ant-design-vue";
export default {
  components: { MyPagination },
  created() {
    this.LogExceptionListFn();
  },
  data() {
    return {
      tableLoading: true, // 表格loading图
      isDisableSearch: false, // 搜索按钮是否禁用
      isDisableEmptyLog: false, // 清空日志按钮是否禁用
      pageNo: 1,
      pageSize: 20,
      count: 0, // 列表总条数
      startTime: "",
      endTime: "",
      searchVal: "", // 输入框的值
      abnormal: "", // 异常来源的默认值
      abnormalArr: [
        { key: "", value: "全部状态" },
        { key: "1", value: "app-consumer" },
        { key: "2", value: "app-provider" },
        { key: "3", value: "admin-consumer" },
        { key: "4", value: "admin-provider" },
      ], // 异常来源的数组
      columns: [
        {
          title: "异常ID",
          dataIndex: "key",
          width: "15%",
          key: "key",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "异常来源",
          dataIndex: "code",
          key: "code",
          width: "10%",
          scopedSlots: { customRender: "code" },
        },
        {
          title: "标题",
          key: "title",
          dataIndex: "title",
          scopedSlots: { customRender: "title" },
        },
        {
          title: "时间",
          dataIndex: "createTime",
          width: "15%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
    };
  },
  methods: {
    // 创建日期改变
    onChangeDate(date, dateString) {
      if (dateString[0] != "") {
        this.startTime = dateString[0] + " 00:00:00";
        this.endTime = dateString[1] + " 23:59:59";
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
    // 异常来源下拉框改变的回调
    changeAbnormal(value) {
      this.abnormal = value;
      this.LogExceptionListFn();
    },
    // 回车和搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.LogExceptionListFn();
    },
    // 初始化的按钮
    startClick() {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "是否进行初始化操作",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        width: "500px",
        onOk() {
          SaveBatchErrorLogApi().then(({ code }) => {
            if (code == 200) {
              message.success("初始化成功", 2);
              that.pageNo = 1;
              that.LogExceptionListFn();
            }
          });
        },
      });
    },
    // 清空日志按钮
    emptyClick() {
      let that = this;
      if (this.tableData.length > 0) {
        Modal.confirm({
          title: "提示",
          content: "删除后不可恢复, 是否清除所有日志",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          width: "500px",
          onOk() {
            ExceptionDellAllApi().then(({ code }) => {
              if (code == 200) {
                message.success("删除成功", 2);
                that.tableData = [];
              }
            });
          },
        });
      } else {
        this.isDisableEmptyLog = true;
        setTimeout(() => {
          this.isDisableEmptyLog = false;
        }, 3000);
        message.success("没有异常日志", 2);
      }
    },
    // 查看的按钮
    seeClick(record) {
      location.href = `/systemManage/systemAbnormal/systemAbnormalDetail/${record.key}`;
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.LogExceptionListFn();
    },
    // 获取问题列表函数
    LogExceptionListFn() {
      LogExceptionListApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        startTime: this.startTime, // 搜索开始日期
        endTime: this.endTime, // 搜索结束日期
        code: this.abnormal, // 异常来源
        id: this.searchVal, // 异常id
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.forEach((item) => {
            item.key = item.id;
            item.title = item.title ? item.title : "———";
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#system_abnormal");
    },
  },
};
</script>
 
<style lang="less" scoped>
#system_abnormal {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
      }
      /deep/.ant-select {
        margin-left: 10px;
      }
    }
  }
}
.btn {
  margin-top: 20px;
  /deep/.ant-btn {
    margin-right: 20px;
  }
}
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>